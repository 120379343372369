/* General Page Styling */
.blog-landing {
    padding: 2rem;
    max-width: 1200px;
    margin: 0 auto;
    background-color: #f9f9f9;
    font-family: 'Arial', sans-serif;
    color: hsl(0, 0%, 20%);
    animation: fadeIn 1s ease-in-out;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

/* Title Styling */
.blog-landing__title {
    font-size: 2.5rem;
    font-weight: 600;
    margin-bottom: 1.5rem;
    color: #1a202c;
    /* Adjusted color for emphasis */
    text-align: center;
    line-height: 1.2;
}

/* Introduction Paragraph Styling */
.blog-landing__intro {
    font-size: 1.2rem;
    line-height: 1.6;
    color: #4a5568;
    /* Softer tone for readability */
    text-align: center;
    margin-bottom: 2rem;
}


/* Responsive Design */
@media (max-width: 768px) {
    .blog-landing__title {
        font-size: 2rem;
    }

    .blog-landing__intro {
        font-size: 1rem;
    }

    .blog-posts.grid .blog-post {
        width: calc(50% - 20px);
    }
}