.pricing-form-section {
    padding: 20px;
    margin: 20px auto;
    max-width: 600px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.pricing-form-section h2 {
    font-size: 1.8rem;
    color: #333;
    margin-bottom: 20px;
    font-weight: bold;
}

.pricing-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.form-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
}

.form-group label {
    font-size: 14px;
    font-weight: bold;
    color: #333;
}

.form-group input,
.form-group textarea,
.form-group select {
    width: 100%;
    padding: 10px;
    font-size: 14px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    color: #555;
}

.form-group input:focus,
.form-group textarea:focus,
.form-group select:focus {
    outline: none;
    border-color: #0073e6;
    box-shadow: 0 0 5px rgba(0, 115, 230, 0.2);
}

textarea {
    resize: vertical;
    min-height: 80px;
}

.cta-button {
    padding: 12px 20px;
    font-size: 16px;
    font-weight: bold;
    color: white;
    background-color: #3e9c35;
    /* Matches the pricing text */
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.cta-button:hover {
    background-color: #2c6a26;
    /* Darker green for hover */
}

.cta-button:active {
    transform: scale(0.98);
}

.pricing-form-section select {
    appearance: none;
    background: url('data:image/svg+xml;charset=UTF-8,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 6"%3e%3cpath fill="%23333" d="M0 0l5 6 5-6z"/%3e%3c/svg%3e') no-repeat right 10px center;
    background-size: 10px 6px;
}