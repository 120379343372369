/* .cta {
    width: 100%;
    padding: 50px 20px;
    background: linear-gradient(to bottom, #08496f, #D15A44);
    color: #FFFFFF;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.cta h1 {
    font-size: clamp(2rem, 5vw, 3.5rem);

    margin-bottom: 20px;
    color: #FFFFFF;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);

}

.cta p {
    font-size: 1.3em;
    margin-bottom: 30px;
    color: #F0F0F0;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    line-height: 1.7;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
}

.cta-button {
    background-color: #5AA2CC;
    color: #FFFFFF;
    padding: 8px 12px;
    margin-right: 15px;
    margin-top: 0;
    border-radius: 5px;
    text-decoration: none;
    font-weight: bold;
    display: inline-block;
    font-size: .8rem;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.cta-button:hover {
    background-color: #F28E72;
    color: #FFFFFF;
    transform: scale(1.05);
    background-color: #F28E72;
} */
.cta {
    width: 100%;
    padding: 50px 20px;
    background: linear-gradient(to bottom, #08496f, #d15a44);
    color: #ffffff;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    margin: 0 auto;
}

.cta h1 {
    font-size: clamp(2rem, 5vw, 3rem);
    margin-bottom: 20px;
    color: #ffffff;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}

.cta p {
    font-size: 1.2em;
    margin-bottom: 20px;
    color: #f0f0f0;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    line-height: 1.7;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
}

.cta-urgency {
    font-size: 1.1em;
    color: #ffdf6c;
    font-weight: bold;
    margin-bottom: 30px;
}

.cta-buttons {
    display: flex;
    justify-content: center;
    gap: 15px;
    flex-wrap: wrap;
}

.cta-button {
    padding: 12px 24px;
    border-radius: 5px;
    text-decoration: none;
    font-weight: bold;
    font-size: 1em;
    transition: all 0.3s ease;
    text-align: center;
    display: inline-block;
}

.cta-button.primary {
    background-color: #5aa2cc;
    color: #ffffff;
}

.cta-button.primary:hover {
    background-color: #f28e72;
    transform: scale(1.05);
}

.cta-button.secondary {
    background-color: #ffffff;
    color: #08496f;
    border: 2px solid #08496f;
}

.cta-button.secondary:hover {
    background-color: #d15a44;
    color: #ffffff;
    transform: scale(1.05);
}

@media (max-width: 768px) {
    .cta-buttons {
        flex-direction: column;
        align-items: center;
    }

    .cta-button {
        width: 100%;
        max-width: 300px;
    }
}