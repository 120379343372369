.page-transition {
    opacity: 0;
    animation: fadeIn 0.3s forwards;
}

@keyframes fadeIn {
    to {
        opacity: 1;
    }
}



.contact-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    background-color: #f9f9f9;
    background-image: url('../../public/assets/contact-hero.jpg');

}

.contact-container {
    display: flex;
    flex-direction: column;
    max-width: 800px;
    width: 100%;
    background-color: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 2rem;
}

.contact-form {
    padding: 2rem;
}

.contact-form h2 {
    font-size: 2rem;
    margin-bottom: 1rem;
    color: #1a202c;
}

.contact-form p {
    font-size: 1.2rem;
    color: #4a5568;
    margin-bottom: 1.5rem;
}

.form-group {
    margin-bottom: 1.5rem;
}

.form-group label {
    display: block;
    font-size: 1rem;
    margin-bottom: 0.5rem;
    color: #333;
}

.form-group input,
.form-group textarea {
    width: 100%;
    padding: 0.75rem;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.form-group textarea {
    resize: vertical;
    min-height: 150px;
}

.form-group button {
    width: 100%;
    padding: 0.75rem;
    font-size: 1rem;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.form-group button:hover {
    background-color: #0056b3;
}

.contact-info {
    padding: 2rem;
    background-color: #f7fafc;
    border-top: 1px solid #e2e8f0;
    text-align: center;
}

.contact-info h3 {
    font-size: 1.8rem;
    margin-bottom: 1rem;
    color: #1a202c;
}

.contact-info p {
    font-size: 1rem;
    color: #4a5568;
    margin-bottom: 1rem;
}

.contact-info a {
    color: #007bff;
    text-decoration: none;
}

.contact-info a:hover {
    text-decoration: underline;
}

.social-media h4 {
    margin-bottom: 1rem;
    font-size: 1.2rem;
    color: #1a202c;
}

.social-media a {
    display: inline-block;
    margin-right: 1rem;
    color: #007bff;
    text-decoration: none;
    font-size: 1rem;
    border: 1px solid #007bff;
    padding: 5px;
}

.social-media a:hover {
    text-decoration: underline;
}

.contact-image {
    max-width: 800px;
    width: 100%;
}

.contact-image img {
    width: 100%;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}