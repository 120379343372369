/* Container for the blog post */
.blog-post {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: 'Poppins', sans-serif;
    color: #333;
}

/* Title of the blog post */
.blog-post h1 {
    font-size: 2.2rem;
    color: #D15A44;
    /* Primary branding color */
    margin-bottom: 15px;
    text-align: center;
}

/* Date and category information */
.blog-post p em {
    font-size: 0.9rem;
    color: #0781b1a2;
    /* Secondary branding color */
    display: block;
    text-align: center;
    margin-bottom: 20px;
}

/* Featured image styling */
.blog-post img {
    width: 100%;
    height: auto;
    border-radius: 10px;
    margin-bottom: 20px;
}

/* Content styling */
.blog-post div {
    line-height: 1.6;
    font-size: 1rem;
    color: #333;
}

/* Paragraph styling */
.blog-post p {
    margin-bottom: 20px;
}

/* Heading 1 styling */
.blog-post h1 {
    font-size: 2.2rem;
    color: #D15A44;
    margin-bottom: 15px;
    text-align: center;
}

/* Heading 2 styling */
.blog-post h2 {
    font-size: 1.8rem;
    color: #D15A44;
    margin-bottom: 15px;
}

/* Hyperlink styling */
.blog-post a {
    color: #0781b1a2;
    text-decoration: underline;
    transition: color 0.3s ease;
}

.blog-post a:hover {
    color: #D15A44;
    text-decoration: none;
}

/* Embedded asset styling */
.blog-post img {
    max-width: 100%;
    height: auto;
    margin: 20px 0;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

/* Responsive design */
@media (max-width: 768px) {
    .blog-post {
        padding: 15px;
    }

    .blog-post h1 {
        font-size: 1.8rem;
    }

    .blog-post h2 {
        font-size: 1.6rem;
    }
}