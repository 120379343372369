/* .testimonials {
    background-color: #184b64;
    padding: 50px 0;
    text-align: center;
    color: #FFFFFF;

}

.testimonials h1 {
    color: #ff7158;

    font-size: clamp(2.5rem, 4vw, 3rem);

    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);

    margin-bottom: 1.5rem;
}

.testimonials-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;

}

.testimonial {
    background-color: #f7f7f7;

    padding: 20px;
    border-radius: 10px;
    text-align: center;
    transition: transform 0.4s ease, box-shadow 0.4s ease;
    flex: 1;
    min-width: 250px;
    max-width: 300px;
    margin: 0 10px;

}

.testimonial p {
    font-size: 1.2em;
    color: #000000;
    margin-bottom: 15px;
    line-height: 1.5;
}

.testimonial h3 {
    font-size: 1.1em;
    color: #D15A44;
    margin-bottom: 10px;
}

.testimonial:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    background: linear-gradient(to bottom right, #a4cef8, #ffbcb0);
}

.testimonials-button {
    background-color: #5AA2CC;
    color: #FFFFFF;
    padding: 10px 20px;
    border-radius: 5px;
    text-decoration: none;
    font-size: 1.1rem;
    margin-top: 20px;
    display: inline-block;
}

.testimonials-button:hover {
    background-color: #F28E72;
    color: #FFFFFF;
} */

.testimonials {
    background-color: #f5faff;
    padding: 4rem 2rem;
}

.testimonials-header {
    text-align: center;
    margin-bottom: 2rem;
}

.testimonials-header h1 {
    font-size: 2.5rem;
    color: #333333;
}

.testimonials-header p {
    font-size: 1.2rem;
    color: #555555;
}

.testimonials-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
}

.testimonial {
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    padding: 1.5rem;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
}

.testimonial:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.testimonial-text {
    font-size: 1.2rem;
    font-style: italic;
    color: #555555;
    margin-bottom: 1rem;
}

.testimonial-author {
    font-size: 1rem;
    font-weight: bold;
    color: #333333;
}

.testimonials-container .testimonial:nth-child(even) {
    background-color: #f9f9f9;
}