/* Hero Section */
.hero {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    padding: 80px 20px;
    min-height: 500px;
    position: relative;
    overflow: hidden;
    text-align: left;
    background: linear-gradient(rgba(10, 31, 50, 0.24), rgba(10, 31, 50, 0.473)),
        url('../../public/assets/SandpointTop.png') no-repeat center;
    background-size: cover;
}

.hero::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(rgba(10, 31, 50, 0.85), rgba(10, 31, 50, 0.85));
    z-index: -1;
}

/* Hero Content */
.hero-content {
    max-width: 60%;
    text-align: center;
    z-index: 2;
}

.hero-content h1 {
    color: #FF7D47;
    /* Golden orange for main headline */
    font-size: clamp(2.5rem, 5vw, 4rem);
    font-weight: bold;
    text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3);
    margin-bottom: 1rem;
}

.hero-content h2 {
    font-size: clamp(1.5rem, 3vw, 2.5rem);
    color: #5AA2CC;
    /* Light blue for subheading */
    margin-bottom: 1.5rem;
    text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
}

.hero-content p {
    font-size: 1.25rem;
    color: #FFFFFF;
    line-height: 1.8;
    margin: 1.5rem auto;
    padding: 20px;
    background: rgba(255, 255, 255, 0.15);
    backdrop-filter: blur(8px);
    border-radius: 10px;
}

/* Buttons */
.hero-buttons {
    display: flex;
    gap: 15px;
    justify-content: center;
    margin-top: 20px;
}

.hero-button {
    padding: 12px 25px;
    font-size: 1.2rem;
    font-weight: bold;
    color: #FFFFFF;
    background-color: #FF7D47;
    border: none;
    border-radius: 5px;
    text-decoration: none;
    cursor: pointer;
    transition: transform 0.2s ease, background-color 0.2s ease;
}

.hero-button.secondary {
    background-color: #5AA2CC;
    color: #FFFFFF;
}

.hero-button:hover {
    transform: scale(1.05);
    background-color: #FF5D21;
}

/* Value Proposition */
.value-proposition {
    margin: 20px 0;
    list-style: none;
    text-align: center;
    color: #FFFFFF;
    font-size: 1.2rem;
}

.value-proposition li {
    margin: 10px 0;
    padding-left: 20px;
    position: relative;
}

.value-proposition li::before {
    /* content: "✔"; */
    color: #FF7D47;
    /* Icon color matching primary button */
    position: absolute;
    left: 0;
}

/* Responsive Design */
@media (max-width: 768px) {
    .hero {
        flex-direction: column;
        padding: 50px 20px;
    }

    .hero-content {
        max-width: 100%;
    }

    .value-proposition {
        text-align: center;
    }

    .hero-buttons {
        flex-direction: column;
        gap: 10px;
    }
}