.services {
    background-color: #f9f9f9;
    padding: 4rem 2rem;
}

.services-header {
    text-align: center;
    margin-bottom: 2rem;
}

.services-header h1 {
    font-size: 2.5rem;
    color: #0064cf;
}

.services-header p {
    font-size: 1.2rem;
    color: #555555;
    margin-top: 0.5rem;
}

.services-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
}

.service {
    background: #ffffff;
    border: 1px solid #e5e5e5;
    border-radius: 8px;
    text-align: center;
    padding: 1.5rem;
    transition: transform 0.3s, box-shadow 0.3s;
}

.service:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

.service-icon {
    font-size: 2.5rem;
    color: #FF7D47;
    margin-bottom: 1rem;
}

.service h3 {
    font-size: 1.5rem;
    color: #333333;
    margin-bottom: 1rem;
}

.service p {
    font-size: 1rem;
    color: #555555;
    margin-bottom: 1.5rem;
}

.service-btn {
    display: inline-block;
    padding: 10px 15px;
    background-color: #5AA2CC;
    color: #ffffff;
    text-decoration: none;
    border-radius: 5px;
    font-weight: bold;
    transition: background-color 0.3s;
}

.service-btn:hover {
    background-color: #FF7D47;
}


.service:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    background: linear-gradient(to top left, #58aeffa4, #ff9b8980);

}




/* .services {
    background: linear-gradient(to bottom right, #e3f5ff, #bbbbbb);
    padding: 10px;
    text-align: center;
    color: #FFFFFF;

}

.services h1, h2 {
    color: #ff7158;

    font-size: clamp(2rem, 4vw, 3rem);

    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.068);

    margin-bottom: 1rem;
}

.services-container {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    gap: 10px;

}

.service {
    background: #ffffff86;

    padding: 20px;

    margin: 0px;

    width: 220px;

    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.4s ease, box-shadow 0.4s ease;

    text-align: center;
}



.service h3 {
    margin-bottom: 10px;

    color: #D15A44;

    font-size: 1.2rem;


}

.service p {
    text-align: left;
    color: #333333;
    font-size: 1rem;
    line-height: 1.4;
    margin-bottom: 15px;
}

.service-icon {
    font-size: 40px;
    margin-bottom: 15px;
    color: #2890ff;
}

.service-btn {
    background-color: #5AA2CC;
    color: #FFFFFF;
    padding: 8px 12px;
    margin-right: 15px;
    margin-top: 2rem;
    border-radius: 5px;
    text-decoration: none;
    font-weight: bold;
    display: inline-block;
    font-size: .8rem;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.service-btn:hover {
    background-color: #F28E72;
    color: #FFFFFF;
    transform: scale(1.05);
    background-color: #F28E72;
} */