/* .about-snippet {
    background: #000000;
    padding: 50px 20px;
    text-align: left;
    color: #e0dfdf;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 100%;
}

.about-snippet h2 {
    font-size: clamp(2em, 3vw, 2.5em);
    margin: 20px auto;
    text-align: center;
    color: #e64b30;
   
    max-width: 80%;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
   
    letter-spacing: 1px;
    
}

.about-snippet p {
    max-width: 70%;
    margin: 20px auto;
    line-height: 1.7;
    font-size: 1.1em;
    color: #f5f5f5;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
    background-color: rgba(255, 255, 255, 0.05);
    padding: 20px;
    border-radius: 5px;
}

.about-snippet b {
    color: #58aeff;
}

.about-snippet p:first-letter {
    font-size: 1.6em;
    font-weight: bold;
    color: #e64b30;
    margin-right: 0px;

}

@media (max-width: 768px) {
    .about-snippet p {
        max-width: 90%;
        font-size: 1em;
        padding: 15px;
    }
} */






.about-snippet {
    background: linear-gradient(135deg, #000000, #1a1a1a);
    padding: 3rem 1.5rem;
    color: #f4f4f4;
    text-align: center;
    border-radius: 10px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
    margin: 2rem auto;
    max-width: 1100px;
    width: 90%;
}

.about-snippet-header h2 {
    font-size: clamp(2rem, 4vw, 3rem);
    margin-bottom: 1rem;
    color: #e64b30;
    letter-spacing: 1px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.about-snippet-content p {
    font-size: 1.2rem;
    line-height: 1.8;
    margin: 1.5rem auto;
    max-width: 800px;
    text-align: left;
    background-color: rgba(255, 255, 255, 0.05);
    padding: 1.5rem;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(255, 255, 255, 0.1);
}

.about-snippet-content p:first-of-type {
    font-size: 1.3rem;
    font-weight: bold;
}

.about-snippet-content b {
    color: #58aeff;
}

.about-snippet-content p:first-letter {
    font-size: 1.8rem;
    color: #e64b30;
    font-weight: bold;
    margin-right: 2px;
}

@media (max-width: 768px) {
    .about-snippet {
        padding: 2rem 1rem;
    }

    .about-snippet-header h2 {
        font-size: clamp(1.8rem, 5vw, 2.5rem);
    }

    .about-snippet-content p {
        font-size: 1rem;
        padding: 1rem;
    }
}