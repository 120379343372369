/* Blog controls styling */

.blog-post-list a:hover {
    /* color: rgb(0, 0, 0); */
    text-decoration: none;
    /* text-underline-offset: .3rem; */
}

.blog-post-card a:hover {
    /* color: rgb(0, 0, 0); */
    text-decoration: none;
    /* text-underline-offset: .3rem; */
}

.blog-controls {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.latest-articles {
    color: rgba(0, 0, 0, 0.74);
    font-weight: 200;
}

.blog-controls button {
    background-color: #5AA2CC;
    /* Light Blue button color */
    color: #FFFFFF;
    /* White text */
    padding: 8px 10px;
    /* Larger padding for buttons */
    margin-right: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    /* Increased margin between buttons */
    border-radius: 5px;
    text-decoration: none;
    font-weight: bold;
    display: inline-block;
    font-size: .7rem;
    /* Larger font size for button text */
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.blog-controls button:hover {
    background-color: #F28E72;
    /* Light Orange-Red for hover effect */
    color: #FFFFFF;
    /* White text */
    outline: 1px solid #F28E72;
    outline-offset: 2px;
    transform: scale(1.05);
    background-color: #F28E72;
}

.blog-posts.grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    /* Three equal-width columns */
    gap: 20px;
    /* Space between grid items */
    padding: 10px;
}

/* Grid layout for blog posts */
.blog-posts.grid .blog-post-item {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 1rem;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.blog-posts.grid .blog-post-item:hover {
    transform: translate(0px, -4px);
    transition: all ease-in .2s;
}

.blog-post-card {
    padding: 0;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.blog-post-image {
    width: 100%;
    aspect-ratio: 16 / 9;
    /* Maintain a consistent aspect ratio */
    object-fit: cover;
    /* Ensures the image covers the area without distortion */
    border-radius: 8px 8px 0 0;
}

.blog-post-content {
    padding: 15px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-between;
    height: 100%;
}

.blog-post-meta {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.7rem;
    margin-top: auto;
    padding: 5px;
    border-top: 1px solid #e6e6e6;
}

.blog-post-card h2 {
    font-size: 1rem;
    color: #D15A44;
    /* Primary branding color */
    margin-bottom: 10px;
}


/* List layout for blog posts */
.blog-posts.list .blog-post-item {
    width: 100%;
    margin: 15px 0;
    display: flex;
    align-items: center;
}

.blog-posts.list .blog-post-item:hover {
    transform: translate(0px, -4px);
    transition: all ease-in .2s;
}

.blog-post-list {
    border: 1px solid #ccc;
    padding: 5px;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: left;
}

.blog-post-list-image {
    width: 27%;
    height: auto;
    border-radius: 8px;
    margin: 0 15px 0 0;

}

.blog-post-list-content {
    flex: 1;
    margin: 1rem auto;
}

.blog-post-list h2 {
    font-size: 1.5rem;
    color: #D15A44;
    /* Primary branding color */
    margin-bottom: 5px;
}

.blog-post-list p {
    font-size: .9rem;
    margin-bottom: 5px;
    color: black;
    max-width: 70%;
}

.blog-post-list .blog-post-tags {
    font-size: .7rem;
    color: #d1594498;
    margin-bottom: 8px;
}

.blog-post-author {
    color: #098cc0a2;
}

.blog-post-date {
    color: #0781b1a2;
}


@media (max-width: 1024px) {
    .blog-posts.grid {
        grid-template-columns: repeat(2, 1fr);
        /* Two columns on medium screens */
    }
}

/* Responsive adjustments */
@media (max-width: 768px) {

    .blog-posts.grid {
        grid-template-columns: repeat(2, 1fr);
        /* Two columns on smaller screens */
    }

    .blog-post-card h2 {
        font-size: 1rem;
    }

    .blog-post-list-image {
        width: 45%;
        object-fit: cover;
        aspect-ratio: 1;
    }

    .blog-post-list h2 {
        font-size: 1.2rem;
    }
}

@media (max-width: 480px) {

    .blog-posts.grid {
        grid-template-columns: 1fr;
        /* Single column on very small screens */
    }

    .blog-post-card h2 {
        font-size: 1rem;
    }

    .blog-post-list-image {
        width: 45%;
        object-fit: cover;
        aspect-ratio: 2/3;
    }

    .blog-post-list p {
        font-size: .77em;
    }

    .blog-post-list h2 {
        font-size: .95rem;
    }
}

@media (max-width: 390px) {

    .blog-posts.grid {
        grid-template-columns: 1fr;
        /* Single column on very small screens */
    }

    .blog-post-card h2 {
        font-size: 1rem;
    }

    .blog-post-list-image {
        width: 45%;
        object-fit: cover;
        aspect-ratio: 1/2;
    }

    .blog-post-list p {
        font-size: .77em;
    }

    .blog-post-list h2 {
        font-size: .95rem;
    }
}