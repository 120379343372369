/* General Styling */
.page-transition {
    opacity: 0;
    animation: fadeIn 0.3s forwards;
}

@keyframes fadeIn {
    to {
        opacity: 1;
    }
}

.price-disclaimer {
    color: #595959;
}

h2 {
    background: none;
    color: #D15A44;
    margin: 15px 10px;
    font-size: 1.5em;
}

/* General Styles */
body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    line-height: 1.6;
}

.pricing-page {
    padding: 20px;
}

.pricing-header {
    text-align: center;
    margin-bottom: 30px;
}

.pricing-header h1 {
    font-size: 2.5rem;
    color: #333;
}

.pricing-header p {
    font-size: 1.2rem;
    color: #555;
}

/* Pricing Cards */
.pricing-container {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    margin-bottom: 20px;
}

.pricing-card {
    background: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s, box-shadow 0.2s;
}

.pricing-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}

.pricing-card h2 {
    font-size: 1.8rem;
    color: #333;
}

.pricing-card .description {
    font-size: 1rem;
    color: #555;
    margin: 10px 0;
}

.pricing-card .price {
    font-size: 2rem;
    /* color: #0073e6; */
    color: #3e9c35;
    margin: 15px 0;
}

.pricing-card .icon {
    font-size: 2.5rem;
    margin: 15px 0;
}

.pricing-card .plan-duration {
    font-size: 0.9rem;
    color: #777;
    margin-bottom: 15px;
}

.pricing-card .features {
    list-style: none;
    padding: 0;
    margin: 15px 0;
}

.pricing-card .features li {
    margin: 8px 0;
    color: #444;
}

.call-to-action {
    font-weight: bold;
    color: #0073e6;
    margin-top: 10px;
}

.cta-button {
    background: #0073e6;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 10px 15px;
    font-size: 1rem;
    cursor: pointer;
    transition: background 0.2s;
}

.cta-button:hover {
    background: #005bb5;
}

/* Trust Section */
.trust-section {
    text-align: center;
    margin: 40px 0;
}

.trust-section h2 {
    font-size: 2rem;
    color: #333;
}

.testimonials {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.testimonial {
    background: #f1f1f1;
    border-left: 4px solid #0073e6;
    padding: 15px 20px;
    font-style: italic;
}

.testimonial footer {
    margin-top: 10px;
    text-align: right;
    color: #555;
    font-size: 0.9rem;
}

/* Inline Lead Form */
.lead-form {
    background: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    margin: 40px auto;
    max-width: 600px;
}

.lead-form h2 {
    text-align: center;
    font-size: 1.8rem;
    color: #333;
    margin-bottom: 20px;
}

.inquiry-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.inquiry-form .form-group {
    display: flex;
    flex-direction: column;
}

.inquiry-form .form-group label {
    margin-bottom: 5px;
    font-weight: bold;
    color: #333;
}

.inquiry-form .form-group input,
.inquiry-form .form-group textarea {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
}

.inquiry-form .form-group textarea {
    resize: vertical;
    min-height: 80px;
}

.inquiry-form .submit-button {
    background: #0073e6;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 12px 20px;
    font-size: 1rem;
    cursor: pointer;
    transition: background 0.2s;
    text-align: center;
}

.inquiry-form .submit-button:hover {
    background: #005bb5;
}

/* Success Message */
.success-message {
    text-align: center;
    color: #28a745;
    font-size: 1.2rem;
    font-weight: bold;
}

/* 
p {
    margin: auto;
    font-size: 1rem;
    color: #4A4A4A;
} */

/* FAQ Section Styling */
.faq-section {
    margin: 0 auto;
    text-align: left;
    padding: 20px;
    max-width: 90%;
    background-color: #fefefe;
    border: 1px solid #ddd;
    border-radius: 8px;
}

.faq-section h2 {
    text-align: center;
    font-size: clamp(2rem, 4vw, 2.5rem);
    color: #D15A44;
    margin-bottom: 20px;
}

.faq {
    margin-bottom: 10px;
    cursor: pointer;
}

.faq h3 {
    font-size: 1.3rem;
    margin: 10px;
    color: #414141;
    transition: color 0.3s;
}

.faq h3:hover {
    color: #D15A44;
    /* Accent color on hover */
}

.faq p {
    display: none;
    font-size: 1.1rem;
    color: #272727;
    line-height: 1.6;
    margin: 15px;
    padding: 15px;
    background-color: #f9f9f9;
    border-left: 4px solid #D15A44;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    transition: max-height 0.4s ease;
}

.faq.open p {
    display: block;
}

/* Pricing Page Styling */
.pricing-page {
    text-align: center;
    /* padding: 50px 20px; */
    background-color: #f4f4f4;
}

.pricing-header h1 {
    font-size: clamp(2.5rem, 5vw, 3.5rem);
    color: #D15A44;
    font-weight: bold;
}

.pricing-header p {
    font-size: 1.1rem;
    color: #555;
}

/* Popular Plan Highlight */
.popular-plan {
    border: 2px solid #D15A44;
    background-color: #fff5f3;
    position: relative;
}

.popular-plan::before {
    content: "Best Value";
    position: absolute;
    top: -10px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #D15A44;
    color: #fff;
    padding: 5px 15px;
    font-size: 0.9rem;
    font-weight: bold;
    border-radius: 20px;
}

/* Pricing Container */
.pricing-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-grow: 1;
    margin-top: 20px;
}

.pricing-card {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 10px;
    width: 320px;
    padding: 20px;
    margin: 15px;
    transition: transform 0.4s ease, box-shadow 0.4s ease;
}

.pricing-card:hover {
    transform: translateY(-5px);
    /* Lift effect on hover */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
    background: linear-gradient(to bottom right, #fafafa, #fff5f3);
}

.pricing-card h2 {
    font-size: clamp(1.5rem, 3vw, 2rem);
    color: #0077cc;
    font-weight: bold;
}

.price {
    font-size: clamp(1.8rem, 4vw, 2.5rem);
    color: #3e9c35;
    margin: 15px 0;
    font-weight: bold;
}

.plan-duration {
    color: #666;
    font-size: 1rem;
    font-style: italic;
}

.features {
    list-style: none;
    padding: 0;
    margin: 15px 0;
}

.features li {
    font-size: 1rem;
    padding: 5px 0;
    border-bottom: 1px dashed #ddd;
    color: #4A4A4A;
}

.features li:last-child {
    border-bottom: none;
}

/* CTA Buttons */
.cta-button {
    background-color: #D15A44;
    color: white;
    padding: 15px 25px;
    font-size: 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.cta-button:hover {
    background-color: #3e9c35;
    color: #fff;
    transform: scale(1.05);
}

.cta-secondary {
    background-color: #0077cc;
    color: #fff;
    padding: 10px 20px;
}

.cta-secondary:hover {
    background-color: #005fa3;
}

.comparison-table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    font-size: 16px;
    text-align: left;
    background-color: #f9f9f9;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.comparison-table th,
.comparison-table td {
    padding: 12px 15px;
    border: 1px solid #ddd;
}

.comparison-table th {
    background-color: #0073e6;
    /* Use your blue accent */
    color: white;
    font-weight: bold;
}

.comparison-table tr:nth-child(even) {
    background-color: #f2f2f2;
    /* Light gray for alternating rows */
}

.comparison-table tr:hover {
    background-color: #e6f7ff;
    /* Highlight on hover */
}

.comparison-table td:first-child {
    font-weight: bold;
    color: #333;
}

.comparison-table th,
.comparison-table td {
    text-align: center;
}

.comparison-table td {
    color: #555;
}

.comparison-table th:first-child,
.comparison-table td:first-child {
    text-align: left;
    background-color: #eef4fa;
    /* Slightly off-white for the feature column */
}



/* Responsive Design */
@media (max-width: 768px) {
    .pricing-container {
        flex-direction: column;
        align-items: center;
    }

    .pricing-card {
        width: 90%;
    }
}