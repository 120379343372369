header, h1, h2 {
    background: none;
    color: #D15A44;
    margin: px 10px;
    font-size: 1.5em;
}

.projects-section {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    margin-bottom: 15px;
}

.project-image {
    width: 100%;
    height: auto;
    display: block;
}

.project-card-content {
    padding: 20px;
}

.project-card-content h2 {
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: #333;
}

.project-card-content p {
    font-size: 1rem;
    color: #555;
    margin-bottom: 20px;
}

.cta-button {
    display: inline-block;
    padding: 10px 15px;
    font-size: 1rem;
    color: #fff;
    background-color: #0056b3;
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.3s ease;
}

.cta-button:hover {
    background-color: #004099;
}


.page-transition {
    opacity: 0;
    animation: fadeIn 0.3s forwards;
    background: #f9f9f9;
}

@keyframes fadeIn {
    to {
        opacity: 1;
    }
}

.projects-section {
    background: #f9f9f9;
    width: 100%;
    margin: 0px auto;
    padding: 15px 0;
}


.projects-section h1 {
    width: 100%;
    text-align: center;
    color: #f76b53;
    font-size: clamp(2rem, 4vw, 3rem);
    margin: 0;
}

.project-card img {
    border-radius: 8px;
    width: 500px;
    height: 100%;
    aspect-ratio: 16/9;
    margin-bottom: 0;
}

.project-card-content {
    max-width: 100%;
    padding: 0 20px;
}

.project-card-content h2 {
    font-size: 1.5em;
    margin: 10px auto;
    color: #d16957;

}

.project-card-content p {
    font-size: 1em;
    line-height: 1.6;
    color: white;
}


.projects {
    background-color: #f9f9f9;
    padding: 1rem 2rem;
}

.projects-header {
    text-align: center;
    margin-bottom: 2rem;

}


.projects-header h1 {
    font-size: 2.5rem;
    color: #333333;
}


.projects-header p {
    font-size: 1.2rem;
    color: #555555;
    margin-top: 0.5rem;
}

.projects-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 1rem;
}

.project {
    background: #ffffff;
    border: 1px solid #e5e5e5;
    border-radius: 8px;
    text-align: center;
    padding: 1.5rem;
    transition: transform 0.3s, box-shadow 0.3s;
}

.project:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    background: linear-gradient(to bottom right, #7eb9f048, #ffe2dd00);
}

.project-image {
    max-width: 100%;
    height: auto;
    border-radius: 6px;
    margin-bottom: 1rem;
}

.project h3 {
    font-size: 1.5rem;
    color: #D15A44;
    margin-bottom: 0.5rem;
}

.project p {
    font-size: 1rem;
    color: #555555;
}

.projects-button-container {
    text-align: center;
    margin-top: 2rem;
}

.projects-button {
    display: inline-block;
    padding: 12px 20px;
    background-color: #5AA2CC;
    color: #ffffff;
    text-decoration: none;
    font-size: 1rem;
    font-weight: bold;
    border-radius: 5px;
    transition: background-color 0.2s;
}

.projects-button:hover {
    background-color: #FF7D47;
}