.about-page {
    /* font-family: 'Arial, sans-serif'; */
    color: #333;
}

.page-transition {
    opacity: 0;
    animation: fadeIn 0.3s forwards;
}

@keyframes fadeIn {
    to {
        opacity: 1;
    }
}


.hero-section {
    text-align: center;
    background-image: url('../../public/assets/sunset-lake-dock.jpg');
    color: #fff;
    padding: 60px 20px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.hero-section h1 {
    font-family: "Playwrite IT Moderna", cursive;
    font-optical-sizing: auto;
    font-size: 1.8rem;
    color: #5aa2cc;
}

.hero-text {
    background: linear-gradient(to bottom, #0000004f, #52525236);
    border: 3px solid #d6d6d6;
    border-radius: 2px;

}

.hero-section h2 {
    font-size: 1.5em;
    font-weight: 300;
}

.about-section {
    background: linear-gradient(to bottom, #ffffff, #f1f1f1);
    padding: 40px 20px;
}

.about-content {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: left;
}

.about-content h2 {
    font-size: 2em;
    color: #08496f;
    margin-bottom: 20px;
}

.about-content p {
    font-size: 1.2em;
    line-height: 1.6;
    margin-bottom: 20px;
}

.about-content ul {
    list-style-type: disc;
    /* Use a bullet style */
    margin-left: 20px;
    /* Add space to the left for better indentation */
    padding-left: 20px;
    /* Match the margin with padding */
}

.about-content ul li {
    margin-bottom: 10px;
    /* Add some space between list items */
    font-size: 1rem;
    /* Adjust font size for readability */
    line-height: 1.6;
    /* Better spacing between lines */
    color: #333;
    /* Neutral, accessible text color */
}

.about-content ul li::marker {
    color: #007BFF;
    /* Make the bullet match your brand colors */
}

.about-content ul li strong {
    color: #007BFF;
    /* Highlight important text */
    font-weight: bold;
    /* Bold it for emphasis */
}


.cta-section {
    text-align: center;
    background-color: #08496f;
    color: #fff;
    padding: 40px 20px;
    margin-top: 20px;
}

.cta-section h3 {
    font-size: 2.5em;
    margin-bottom: 20px;
}

.cta-section p {
    font-size: 1.5em;
    margin-bottom: 0;
}