.service-details {
    padding: 2rem;
    max-width: 800px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
    background-color: #f9f9f9;
}

.service-details h1 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
    color: #D15A44;
}

.service-details p {
    font-size: 1.2rem;
    line-height: 1.6;
    margin-bottom: 1.5rem;
}

.service-details ul {
    list-style-type: disc;
    margin-left: 1.5rem;
}

.service-details {
    padding: 3rem;
    max-width: 1200px;
    margin: 0 auto;
    font-family: 'Arial', sans-serif;
    line-height: 1.6;
    color: #333;
}

.header h1 {
    font-size: 2.8rem;
    color: #D15A44;
    margin-bottom: 1rem;
}

.header p {
    font-size: 1.2rem;
    color: #555;
}

.content h2 {
    font-size: 2rem;
    margin-top: 2rem;
    color: #D15A44;
}

.content p {
    font-size: 1.1rem;
    margin-bottom: 1.5rem;
}

.benefits-list {
    list-style: none;
    padding: 0;
}

.benefits-list li {
    font-size: 1.1rem;
    margin-bottom: 0.5rem;
    display: flex;
    align-items: center;
}

.benefits-list li:before {
    content: "✔";
    color: #27ae60;
    margin-right: 0.5rem;
}

.process-steps {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 2rem;
    margin-top: 1.5rem;
}

.step h3 {
    font-size: 1.5rem;
    color: #2980b9;
}

.step p {
    font-size: 1rem;
}

.portfolio-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 1.5rem;
    margin-top: 1rem;
}

.portfolio-item {
    text-align: center;
}

.portfolio-item img {
    width: 100%;
    height: auto;
    border-radius: 8px;
    margin-bottom: 0.5rem;
}

.cta-button {
    display: inline-block;
    padding: 0.8rem 2rem;
    background-color: #2980b9;
    color: #fff;
    font-size: 1.1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 1.5rem;
    transition: background-color 0.3s ease;
    margin-right: 20px;
}

.cta-button:hover {
    background-color: #D15A44;
    ;
}