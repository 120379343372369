footer {
    background-color: #0A1F32;
    /* Dark blue for footer background */
    padding: 20px 0;
    color: #FFFFFF;
    /* White text */
    font-family: Arial, sans-serif;
}

.footer-container {
    display: flex;
    flex-wrap: wrap;
    max-width: 1200px;
    margin: 0 auto;
    justify-content: space-between;
    align-content: center;
}

.footer-section {
    flex: 1;
    min-width: 250px;
    padding: 10px;
}

.footer-section h4 {
    margin-bottom: 15px;
    color: #D15A44;
    /* Orange-Red for section headers */
}

.footer-section ul,
.footer-section p {
    margin: 4px;
    padding: 0;
    list-style-type: none;
}

.footer-section ul li a {
    color: #5AA2CC;
    /* Light Blue for links */
    text-decoration: none;
    transition: color 0.3s;
}

.footer-section ul li {
    margin-bottom: 5px;
}

.footer-section ul li a:hover {
    color: #F28E72;
    /* Light Orange-Red for hover effect */
}

.footer-bottom {
    text-align: center;
    margin-top: 20px;
}

.footer-bottom p {
    margin: 0;
    color: #A9A9A9;
    /* Gray for footer bottom text */
}

.footer-container a {
    color: #FFFFFF;
    /* White for default link color */
    text-decoration: none;
}

.footer-container span {
    margin-right: 8px;
}

.footer-section a:hover {
    color: #F28E72;
    /* Light Orange-Red for hover effect */
}