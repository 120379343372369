/* SEOPage.css */

.seo-page {
    font-family: 'Arial', sans-serif;
    background-color: #f9f9f9;
    padding: 50px 20px;
}

.seo-header h1 {
    font-size: clamp(2rem, 5vw, 3.5rem);
    color: #ff5031;
    margin-bottom: 15px;
}

.seo-header p {
    font-size: 1.1rem;
    color: #777;
    margin-bottom: 30px;
}

.seo-services {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    margin-top: 20px;
}

.service-card {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 10px;
    width: 300px;
    padding: 20px;
    margin: 15px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.service-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.service-card h2 {
    font-size: clamp(1.3rem, 3vw, 2rem);
    color: #0077cc;
    margin-bottom: 10px;
}

.service-card {
    font-size: 1.1rem;
    color: #272727;
    margin-bottom: 10px;
}

.service-card ul {
    list-style: none;
    padding: 0;
    margin-bottom: 15px;
}

.service-card li {
    font-size: 1.1rem;
    padding: 5px 0;
    border-bottom: 1px solid #ddd;
}

.price {
    font-size: clamp(1.5rem, 3vw, 2rem);
    color: #3e9c35;
    margin: 10px 0;
}

.cta-button {
    background-color: #D15A44;
    color: white;
    padding: 10px 20px;
    font-size: 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.cta-button:hover {
    background-color: #005fa3;
}

.seo-footer {
    text-align: center;
    margin-top: 50px;
    background-color: #f4f4f4;
    padding: 30px;
    border-radius: 10px;
}

.seo-footer h3 {
    font-size: 1.8rem;
    color: #0077cc;
    margin-bottom: 15px;
}

.seo-footer p {
    font-size: 1.2rem;
    color: #555;
    margin-bottom: 20px;
}